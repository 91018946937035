import React, { useState } from 'react';

import {
  FormRow,
  Input,
  MessageAlert,
  MessageAlertType,
} from 'web/components/primitives';
import FormContainer from 'web/components/public/form-container';
import { postForgotPassword } from 'shared/api/session';

import styles from './styles.scss';

export default function ForgotPassword() {
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState();
  const [form, setForm] = useState({
    email: '',
  });

  const onSubmit = () =>
    postForgotPassword(window.clientConfig.baseUrl, form.email)
      .then(() => {
        setForm({ email: '' });
        setSuccessMessage(
          "If an account exists with that email or username, we have reached out with instructions to reset your password. If you haven't received an email, please check your phone for a text.",
        );
      })
      .catch(err => setError(err.message));

  const onSetForm = update => {
    if (error) {
      setError();
    }
    if (successMessage) {
      setSuccessMessage('');
    }
    setForm({ ...form, ...update });
  };

  let messageType;
  let message = (
    <>
      If you do not have an email address or username associated with your
      LifeLoop account, you should contact your community&#39;s LifeLoop
      administrator or{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
        href="https://lifeloop.com/customer-support"
      >
        submit a support request
      </a>
      .
    </>
  );
  if (error) {
    messageType = MessageAlertType.error;
    message = error;
  } else if (successMessage) {
    messageType = MessageAlertType.success;
    message = successMessage;
  }

  return (
    <FormContainer
      title="Forgot Password"
      isValid={!!form.email}
      onSubmit={onSubmit}
      additionalLink={{
        title: 'Go to Login',
        link: '/login',
      }}
    >
      <MessageAlert type={messageType}>
        <span>{message}</span>
      </MessageAlert>
      <FormRow label="Email or Username">
        <Input
          autoComplete
          id="email"
          name="email"
          value={form.email}
          onChange={e => onSetForm({ email: e.target.value })}
        />
      </FormRow>
    </FormContainer>
  );
}
