import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon, { IconType } from 'stories/icon';

import FlexContainer from 'web/components/primitives/flex-container';

import styles from './styles.scss';

export default function Checkbox({
  value,
  onChange,
  description,
  disabled,
  locked,
  className,
  name,
  ...props
}) {
  const hiddenInputRef = useRef(null);

  useEffect(() => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = value;
    }
  }, [value]);

  return (
    <>
      <FlexContainer
        alignItems="center"
        className={classNames(className, styles.wrapper, {
          [styles.selected]: value,
          [styles.disabled]: disabled,
          [styles.locked]: locked,
        })}
        onClick={e => {
          e.stopPropagation();
          if (disabled || locked) {
            return;
          }
          onChange(!value);
        }}
        role="button"
        tabIndex={0}
        aria-pressed={value}
        data-qa-checkbox={value}
        {...props}
      >
        <Icon
          className={classNames(styles.icon, {
            [styles.disabled]: disabled,
            [styles.locked]: locked,
          })}
          type={value || locked ? IconType.checkSquare : IconType.square}
        />
        {!!description && (
          <div
            className={classNames(styles.description, {
              [styles.disabled]: disabled,
              [styles.locked]: locked,
            })}
          >
            {description}
          </div>
        )}
      </FlexContainer>
      <input type="hidden" name={name} ref={hiddenInputRef} />
    </>
  );
}

Checkbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  value: false,
  description: undefined,
  disabled: false,
  locked: false,
};
